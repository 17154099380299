import axios from "axios"
import { apiURL } from "services/auth"

export const createClass = newClass => axios.post(`${apiURL}/kelas/`, newClass)

export const updateClass = kelas =>
  axios.patch(`${apiURL}/kelas/${kelas.get("id")}/`, kelas)

export const getAllClasses = () => axios.get(`${apiURL}/kelas/`)

export const getAllTrendingClasses = () => axios.get(`${apiURL}/trending-kelas/`)

export const getAllClassWhatsOn = () =>
  axios.get(`${apiURL}/konten-whats-goingon/`)

export const getOneClass = slug => axios.get(`${apiURL}/kelas/?slug=${slug}`)

export const getClassUsingId = id => axios.get(`${apiURL}/kelas/${id}/`)

export const getRatingByKelas = id => axios.get(`${apiURL}/rating/?kelas=${id}`)

export const getRatingByUser = id =>
  axios.get(`${apiURL}/rating/?pembeli=${id}`)

export const createRating = rating => axios.post(`${apiURL}/rating/`, rating)

// WISHLIST

export const getMyWishlist = () => axios.get(`${apiURL}/wishlist/`)

export const getMyWishlistByKelas = id =>
  axios.get(`${apiURL}/wishlist/?kelas=${id}`)

export const addWishlist = kelas => axios.post(`${apiURL}/wishlist/`, kelas)

export const removeWishlist = kelas =>
  axios.delete(`${apiURL}/wishlist/${kelas}/`)

export const createJadwalGroup = async (jadwalGroup, jadwals) => {
  const resp = await axios.post(`${apiURL}/jadwal-group/`, jadwalGroup)
  return await jadwals.forEach(async jadwal => {
    jadwal.jadwal_group = resp.data.id
    await axios.post(`${apiURL}/jadwal/`, jadwal)
  })
}

export const editJadwalGroup = async (jadwalGroup, jadwals, oldJadwals) => {
  if (jadwals.length === 0) {
    return await axios.delete(`${apiURL}/jadwal-group/${jadwalGroup.pk}/`)
  } else {
    for (let i = 0; i < Math.min(oldJadwals.length, jadwals.length); ++i) {
      await axios.patch(`${apiURL}/jadwal/${oldJadwals[i].id}/`, {
        ...jadwals[i],
        id: oldJadwals[i].id,
      })
    }
    if (oldJadwals.length > jadwals.length) {
      for (let i = jadwals.length; i < oldJadwals.length; ++i) {
        await axios.delete(`${apiURL}/jadwal/${oldJadwals[i].id}/`)
      }
    } else {
      for (let i = oldJadwals.length; i < jadwals.length; ++i) {
        await axios.post(`${apiURL}/jadwal/`, {
          ...jadwals[i],
          jadwal_group: jadwalGroup.pk,
        })
      }
    }
  }
}

export const invitePengajar = data => axios.post(`${apiURL}/kelas-tutor/`, data)

export const removePengajar = id => axios.delete(`${apiURL}/kelas-tutor/${id}/`)

export const becomeATutor = data => axios.post(`${apiURL}/tutor/`, data)

export const updateTutor = data =>
  axios.patch(`${apiURL}/tutor/${data.id}/`, data)

export const getTutorMe = () => axios.get(`${apiURL}/tutor/me/`)

export const getCreatedClasses = () => axios.get(`${apiURL}/created-class/`)

export const acceptTutorInvite = id =>
  axios.patch(`${apiURL}/kelas-tutor/${id}/`, {
    status: "2",
  })

export const declineTutorInvite = id =>
  axios.delete(`${apiURL}/kelas-tutor/${id}/`)

export const getDiskonPerPaket = slug =>
  axios.get(`${apiURL}/diskon-per-paket/?slug=${slug}`)
