import styled from "styled-components"

export const Styles = styled.div`
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);

  width: 285px;

  .img-container {
    height: 268px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    padding: 20px 24px;
    display: flex;
    align-items: end;

    .categories-black-wrapper {
      display: flex;
      padding: 2px 8px;
      border: 1px solid #ffffff;
      background: rgba(59, 59, 59, 0.65);
      border-radius: 12px;
      color: #ffffff;
      font-size: 11px;

      .categories-black-icon-wrapper {
        position: relative;
        margin-right: 8px;
      }
    }
  }

  .class-card-content {
    padding: 24px;

    .class-card-title {
      font-weight: bold;
      letter-spacing: 0.0094em;
    }

    .class-card-author {
      font-size: 12px;
      letter-spacing: 0.025em;
      color: #818181;
    }

    .class-card-rating {
      display: flex;
      color: #919191;
      font-size: 12px;
    }
  }

  .ongoing-detail-button {
    width: 100%;
    padding: 5px 16px;
    border-radius: 6px;
    border: 0;
    font-size: 14px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  }

  .ongoing-option-button {
    padding: 5px 6px;
    background: #e7e7e7;
    border: 0;
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  }

  .rating-finished,
  .rating-submit {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
  }

  .rating-finished > span,
  .rating-submit > span {
    width: 100%;
  }

  .rating-finished span > span:first-child,
  .rating-submit span > span:first-child {
    width: 100% !important;
  }

  .rating-finished span > span,
  .rating-submit span > span {
    display: flex !important;
    justify-content: center;
    overflow: visible !important;
  }

  .rating-finished img {
    width: 80%;
  }

  .rating-submit img {
    width: 90%;
  }

  .wishlist-button {
    line-height: 1.5;
    background-color: #f5fff5;
    color: #51b848;
    border-color: #cbe9c8;
    font-weight: bold;
    font-size: 14px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  }

  .wishlist-button span {
    height: 100%;
  }

  .wishlist-img-wrapper {
    width: 15px;
    height: auto;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }

  .wishlist-img-wrapper img {
    height: 12px;
  }

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  .card-nonimage {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .card-image {
      display: none;
    }

    .card-nonimage {
      display: block;
    }

    .rating-submit img {
      width: 50%;
    }
  }
`
