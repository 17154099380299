import React from "react"
import { Styles } from "./style"

import afterClassBlack from "images/classes/tipe-kelas/afterclass-black.svg"
import tamanIkhlasBlack from "images/classes/tipe-kelas/tamanikhlas-black.svg"
import tamanPlusBlack from "images/classes/tipe-kelas/tamanplus-black.svg"
// import ITWhiteIcon from "images/classes/categories/it-white.svg"
import starEmpty from "images/star-empty.svg"

import { formatMoney } from "../../../services/utils"

import { sendAmplitudeData } from "trackers/amplitude"
import { Link } from "gatsby"

export const NewClassCardContent = ({
  data = {},
  noPriceTag = false,
  noLink,
}) => {
  const getTypeBadge = type => {
    if (type === "TP") return <img src={tamanPlusBlack} alt="TamanClass Plus" />
    else if (type === "TS")
      return <img src={tamanIkhlasBlack} alt="TamanClass Seikhlasnya" />
    return <img src={afterClassBlack} alt="AfterClass" />
  }
  const typeBadge = getTypeBadge(data.type)

  return noLink ? (
    <>
      <div
        className="img-container"
        style={{
          backgroundImage: `url(${data.image})`,
        }}
      >
        <div>
          {/* <div className="categories-black-wrapper mb-2">
            <div className="categories-black-icon-wrapper">
              <img src={ITWhiteIcon} alt="" style={{ width: "12px" }} />
            </div>
            <span>{data.category}</span>
          </div> */}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => data.onClickTipe(data.type)}
          >
            {typeBadge}
          </div>
        </div>
      </div>
      <div className="class-card-content">
        <div className="mb-3">
          <div className="class-card-title">{data.title}</div>
          <div className="class-card-author">{data.author}</div>
        </div>
        {noPriceTag || (
          <div>
            <div className="class-card-price class-card-title">
              Rp{data.price}
            </div>
            <div className="class-card-rating">
              <img style={{ width: "14px" }} src={starEmpty} alt="" />
              <div
                style={{
                  marginLeft: "4px",
                  color: "#919191",
                  marginTop: "2.5px",
                }}
              >
                {data.rating}{" "}
                <span style={{ marginLeft: "4px", marginRight: "4px" }}>|</span>{" "}
                {data.member} Peserta
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <Link
      onClick={() => {
        sendAmplitudeData("CLASSES_LIHAT_DETIL_KELAS_TITLE_CLICKED")
      }}
      to={`/kelas/${data.code}/`}
    >
      <div
        className="img-container"
        style={{
          backgroundImage: `url(${data.image})`,
        }}
      >
        <div>
          {/* <div className="categories-black-wrapper mb-2">
            <div className="categories-black-icon-wrapper">
              <img src={ITWhiteIcon} alt="" style={{ width: "12px" }} />
            </div>
            <span>{data.category}</span>
          </div> */}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => data.onClickTipe(data.type)}
          >
            {typeBadge}
          </div>
        </div>
      </div>
      <div className="class-card-content">
        <div className="mb-3">
          <div className="class-card-title">{data.title}</div>
          <div className="class-card-author">{data.author}</div>
        </div>
        {noPriceTag || (
          <div>
            <div className="class-card-price class-card-title">
              {data.price > 0 ? `Rp${formatMoney(data.price)}` : "Gratis"}
            </div>
            <div className="class-card-rating">
              <img style={{ width: "14px" }} src={starEmpty} alt="" />
              <div
                style={{
                  marginLeft: "4px",
                  color: "#919191",
                  marginTop: "2.5px",
                }}
              >
                {data.rating}{" "}
                <span style={{ marginLeft: "4px", marginRight: "4px" }}>|</span>{" "}
                {data.member} Peserta
              </div>
            </div>
          </div>
        )}
      </div>
    </Link>
  )
}

function NewClassCard(props) {
  return (
    <Styles className={props.className}>
      <NewClassCardContent data={props} />
    </Styles>
  )
}

export default NewClassCard
